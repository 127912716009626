import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Layout } from "../components/Layout";
import { useAddItemToCart } from "../components/StoreContext";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Product({ pageContext: { product, type } }) {
  const [quantity, setQuantity] = useState(1);
  const [added, setAdded] = useState(false);
  const addItemToCart = useAddItemToCart();

  const price = product.variants[0].price;
  const id = product.variants[0].id.split("__")[2];

  const handleAddToCart = async (e) => {
    e.preventDefault();
    return await addItemToCart(id, quantity).then(() => setAdded(true));
  };

  return (
    <Layout>
      <main className="mb-12 max-w-screen-xl ml-auto mr-auto text-center p-6">
        <div className="flex flex-col lg:flex-row justify-around items-center mb-12">
          <GatsbyImage
            className="mb-4 lg:mb-0 lg:p-4 w-full lg:w-2/4"
            image={product.images[0].localFile.childImageSharp.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            alt={product.title}
            placeholder="blurred"
          />
          <div className="max-w-lg text-left lg:p-4 xl:p-0">
            <h2 className="text-2xl mb-2">{product.title}</h2>
            <h3>${price}</h3>
            <form className="mb-8">
              <label htmlFor="Quantity">Quantity:</label>
              <input
                className="border border-black rounded-md p-2 m-2 w-16"
                type="number"
                name="quantity"
                min="1"
                onChange={({ target }) => setQuantity(target.value)}
                value={quantity}
              />
              <button
                onClick={handleAddToCart}
                className="rounded-md m-2 p-2 bg-blue-500 text-white"
              >
                Add to Cart
              </button>
              <span style={{ display: "inline-block", marginLeft: "5px" }}>
                {added && "Added!"}
              </span>
            </form>
            <div
              dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
            />
          </div>
        </div>
        <p>
          <a href={`/${type}`}>
            <i className="fas fa-chevron-left mr-2"></i>See More {type}
          </a>
        </p>
      </main>
    </Layout>
  );
}
